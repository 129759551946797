import Categories from "../Components/Categories";
import AuthForm from "../Components/Auth/AuthForm";
const Login = () => {
  return (
    <>
      {/* <Categories /> */}
      <div className="mt-5">
        <AuthForm />
      </div>
    </>
  );
};

export default Login;
